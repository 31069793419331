//资源下载中心
<template>

    <div class="resource_center">

        <!-- 内容布局 -->
        <div class="content" v-if="!showLoading">

            <a target="_blank" class="item_resource_down" v-for="(item,index) in resoureArr" :key="index" :href="item.url" download>
                <img class="down_img" src="../../assets/img/down_file.png">
                <div class="text">{{item.name}}</div>
            </a>

            <!-- <div @click.stop="downLoadAction(item)" class="item_resource_down" v-for="(item,index) in resoureArr" :key="index">
                <span class="iconfont down_icon cloud-xiazai"></span>
                <div class="text">{{item.name}}</div>
            </div> -->

        </div>

        <!-- 加载框 -->
        <Loading v-if="showLoading"></Loading>

    </div>

</template>
<script>
//引入混入文件
import screen from "../../mixin/screen";
export default {
    name: "ResourceCenter",
    mixins: [screen],
    data() {
        return {
            //加载框
            showLoading: true,

            //资源中心数据
            resoureArr: [],
        };
    },
    created() {
        //请求数据
        this.httpData();
    },

    mounted() {},
    methods: {
        //获取列表数据
        async httpData() {
            let param = this.getHttpParams();
            param.type = 4;
            param.curriculumCenterId = this.curriculumCenterId;
            param.curriculumChapterId = this.curriculumChapterId;
            param.chapterClassId = this.chapterClassId;
            param.sectionId = 4;
            let res = await this.$http.fetchPost(
                this.$api.LESSON_CENTER,
                param
            );
            this.showLoading = false;
            if (res.data.state == 200) {
                //获取下载地址
                let url = res.data.data[0].url + "";

                //截取名称
                let startPos = url.lastIndexOf("/");

                let endPos = url.lastIndexOf(".");

                let name = url.substring(startPos + 1, endPos);

                this.resoureArr.push({ name, url });
            } else {
                //直接将服务器返回的错误内容弹出提示
                this.showErrorMsg(res.data.msg);
            }
        },

        //下载的方法
        downLoadAction(item) {
            thunderLink.newTask({
                downloadDir: "", // 指定当前任务的下载目录名称，迅雷会在用户剩余空间最大的磁盘根目录中创建这个目录。【若不填此项，会下载到用户默认下载目录】
                tasks: [
                    {
                        name: item.name, // 指定下载文件名（含扩展名）。【若不填此项，将根据下载 URL 自动获取文件名】
                        url: item.url, // 指定下载地址【必填项】
                    },
                ],
            });
        },
    },
    props: ["curriculumCenterId", "curriculumChapterId", "chapterClassId"],
    computed: {},
    components: {},
};
</script>
<style lang="scss" scoped>
.resource_center {
    background: black;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
        width: 85%;
        height: 90%;
        border-radius: 12px;
        background: white;
        display: flex;
        align-content: flex-start;

        .item_resource_down {
            cursor: pointer;
            display: flex;
            height: 140px;
            margin: 0 20px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .down_img {
                width: 58px;
            }

            .text {
                margin-top: 8px;
                font-size: 14px;
                font-weight: bold;
                color: black;
            }
        }
    }
}
</style>
