// python下载
<template>
    <div class="python_download">
        <!-- 内容布局 -->
        <div class="content" v-if="!showLoading">

            <!-- 下载按钮 -->
            <div class="download">
                <div title="下载" class="iconfont cloud-xiazai2" @click.stop="dowloadFile"></div>
                <span class="tip_msg">资源下载</span>
            </div>

        </div>
        <!-- 加载框 -->
        <Loading v-if="showLoading"></Loading>
    </div>
</template>
<script>
export default {
    name: "TeachingPlanDown",
    mixins: [screen],
    data() {
        return {
            showLoading: true,
            //当前加载的downLoadUrl
            downLoadUrl: "",
            //下载的文件名称
            fileName: "",
        };
    },
    created() {
        //请求数据
        this.httpData();
    },
    methods: {
        //获取列表数据
        async httpData() {
            let param = this.getHttpParams();
            param.type = 4;
            param.curriculumCenterId = this.curriculumCenterId;
            param.curriculumChapterId = this.curriculumChapterId;
            param.chapterClassId = this.chapterClassId;
            param.sectionId = 5;
            let res = await this.$http.fetchPost(
                this.$api.LESSON_CENTER,
                param
            );
            this.showLoading = false;
            if (res.data.state == 200) {
                //获取PDF的展示地址
                this.downLoadUrl = res.data.data[0].url;

                //获取下载文件名称
                this.fileName = res.data.data[0].name.trim();
            } else {
                //直接将服务器返回的错误内容弹出提示
                this.showErrorMsg(res.data.msg);
            }
        },

        //下载文件
        dowloadFile() {
            fetch(this.downLoadUrl)
                .then(async (res) => await res.blob())
                .then((blob) => {
                    // 创建隐藏的可下载链接
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = URL.createObjectURL(blob);
                    // 保存下来的文件名
                    a.download = this.fileName;
                    document.body.appendChild(a);
                    a.click();
                    // 移除元素
                    document.body.removeChild(a);
                });
        },
    },

    props: ["curriculumCenterId", "curriculumChapterId", "chapterClassId"],
    computed: {},
    components: {},
};
</script>
<style lang="scss" scoped>
.python_download {
    background: black;
    display: flex;
    align-items: center;
    flex: 1;
    position: relative;

    //下载按钮
    .download {
        top: 10px;
        left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fdd420;
        font-size: 40px;
        position: absolute;
        cursor: pointer;
        flex-direction: column;

        .cloud-xiazai2 {
            font-size: 40px;
        }
        .tip_msg {
            color: white;
            font-size: 12px;
            margin-top: 6px;
        }
    }
}
</style>
