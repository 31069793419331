//开始学习界面
<template>
  <div class="study">
    <!-- 顶部的返回以及导航条部分 -->
    <div class="top">
      <!-- 返回 -->
      <div @click.stop="goBack" class="back iconfont cloud-fanhui"></div>
      <!-- 占位 -->
      <div class="placeholder"></div>
      <!-- 当前小节文字 -->
      <div class="curr_name">当前小节</div>
      <!-- 导航条 -->
      <div class="top_nav">
        <div
          class=""
          :class="['item_nav', currPos == index ? 'item_nav_select' : 'item_nav_normal']"
          v-for="(item, index) in navArr"
          :key="index"
          @click.stop="doTopNavClick(item, index)"
          :title="item.title"
        >
          {{ index + 1 }}
        </div>
      </div>
      <!-- 头像 -->
      <img class="img_head" @click.stop="goPersonInfo" :src="user_img" width="32" height="32" />
    </div>

    <!-- 底部切换PPT,慕课视频,答题,测评的组件,做保活处理 -->
    <keep-alive :include="['TeachingPlan', 'ClassPpt']">
      <component :is="currentView" :curriculumCenterId="curriculumCenterId" :curriculumChapterId="curriculumChapterId" :chapterClassId="chapterClassId" />
    </keep-alive>
  </div>
</template>
<script>
//上课ppt
import ClassPpt from "./ClassPpt.vue";
//引入教案组件
import TeachingPlan from "./TeachingPlan.vue";
//项目范例
import ClassPptThird from "./ClassPptThird.vue";
//引入拼搭图PDF展示界面
import TickPicturePdf from "./TickPicturePdf.vue";
//引入资源中心
import ResourceCenter from "./ResourceCenter.vue";
//引入带下载的教案组件
import TeachingPlanDown from "./TeachingPlanDown.vue";
//引入Python资源下载中心
import PythonDownload from "./PythonDownload.vue";
import screen from "../../mixin/screen";
export default {
  mixins: [screen],
  data() {
    return {
      //顶部导航条选中的索引,默认进来第一个选中
      currPos: 0,
      //当前显示的组件
      currentView: "",
      //顶部导航条的数据,需要根据不同课程来动态传递
      navArr: [],
      //用户头像
      user_img: "",
      //每个类型组件请求时需要传递的参数
      curriculumCenterId: "",
      curriculumChapterId: "",
      chapterClassId: "",
      //记录进入页面时history的长度
      rlen: history.length
    };
  },
  created() {
    //初始化数据
    this.initData();

    //设置用户头像
    this.setUserInfo();
  },
  mounted() {
    //禁用右键相关功能
    this.prohibit();
  },

  methods: {
    //初始化数据
    initData() {
      //初次进入的时候从localStory中取出保存的顶部Tab栏的索引
      let curPos = localStorage.getItem("study_pos");
      if (this.isEmpty(curPos)) {
        curPos = 0;
      }
      //将顶部的导航栏的索引位置选中
      this.currPos = curPos;

      //获取传递参数
      this.curriculumCenterId = this.$route.query.curriculumCenterId;
      this.curriculumChapterId = this.$route.query.curriculumChapterId;
      this.chapterClassId = this.$route.query.chapterClassId;

      //获取章节类型的标识,类似1-2-3这种标识
      this.sectionType = this.$route.query.sectionType;

      //先清空顶部导航条的数据
      this.navArr.splice(0, this.navArr.length);

      if (!this.isEmpty(this.sectionType)) {
        //1:使用-切割获取课程对应的各个组件
        let comArrs = this.sectionType.split("-");

        if (!this.isEmpty(comArrs)) {
          comArrs.forEach(item => {
            this.navArr.push(this.getObjByType(item));
          });
        }
      }
      //加载初始化组件
      this.currentView = this.navArr[curPos].name;
    },

    //获取用户信息
    setUserInfo() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (!this.isEmpty(userInfo)) {
        this.user_img = userInfo.user_img;
      }
    },

    //根绝类型创建对索引对象
    getObjByType(type) {
      let obj = new Object();
      switch (parseInt(type)) {
        case 1:
          obj.name = "ClassPpt";
          obj.title = "上课ppt";
          break;
        case 2:
          obj.name = "TeachingPlan";
          obj.title = "教案";
          break;
        case 3:
          obj.name = "TickPicturePdf";
          obj.title = "拼搭图";
          break;
        case 4:
          obj.name = "TeachingPlanDown";
          obj.title = "资源中心";
          break;
        case 5:
          obj.name = "PythonDownload";
          obj.title = "资源中心";
          break;
      }
      return obj;
    },

    //返回
    goBack() {
      //返回的时候还原索引位置
      localStorage.setItem("study_pos", 0);
      let len = this.rlen - history.length - 1; //-1是不进入iframe页面的下级页面直接退出的话，执行后退一步的操作
      this.$router.go(len);
    },
    //顶部导航的点击事件
    doTopNavClick(item, pos) {
      if (this.currPos != pos) {
        this.currPos = pos;
        //使用组件跳转
        this.currentView = item.name;
        //localStory保存一下位置
        localStorage.setItem("study_pos", pos);
      }
    },

    //跳转到个人信息界面
    goPersonInfo() {}
  },
  computed: {},
  components: {
    TeachingPlan,
    ClassPpt,
    ClassPptThird,
    TickPicturePdf,
    ResourceCenter,
    TeachingPlanDown,
    PythonDownload
  }
};
</script>
<style lang="scss" scoped>
.study {
  display: flex;
  flex-direction: column;
  height: 100%;
  .top {
    display: flex;
    height: 70px;
    min-height: 70px;
    align-items: center;
    background: #302f35;
    color: white;
    .back {
      color: white;
      font-size: 22px;
      font-weight: bold;
      cursor: pointer;
      margin-left: 16px;
    }

    .placeholder {
      flex: 1;
    }

    //当前小节名称
    .curr_name {
      font-size: 14px;
    }

    //导航条
    .top_nav {
      margin: 0 16px;
      display: flex;
      .item_nav {
        cursor: pointer;
        margin-left: 8px;
        margin-right: 8px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        border: 2px solid white;
      }

      //顶部导航条选中的样式
      .item_nav_select {
        background: #fdd420;
      }

      //顶部导航条未选中样式
      .item_nav_normal {
        background: #494b56;
      }
    }

    //头像
    .img_head {
      margin-right: 26px;
      cursor: pointer;
      border-radius: 50%;
    }
  }
}
.study ::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>
